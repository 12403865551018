<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link
            to="/home"
            class="d-flex align-center"
          >
            <v-img
              :src="appLogo"
              max-width="150px"
              alt="logo"
              contain
              class="me-3 "
            ></v-img>
          </router-link>
          <CardI18n />
        </v-card-title>

        <!-- title -->
        <v-card-text>
          <div class="primary--text text-center mb-4">
            {{ $t('LeGrandJardin') }}
          </div>
          <p class="text-2xl font-weight-semibold text--primary mb-2">
            {{ $t('Welcome') }}
          </p>
          <p
            class="mb-2"
          >
            {{ $t('LoginSubtitle') }}
          </p>
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <v-form
            ref="loginForm"
            @submit.prevent="handleFormSubmit"
          >
            <v-text-field
              v-model="phoneNumber"
              dense
              outlined
              type="tel"
              :label="$t('PhoneNumber')"
              :placeholder="$t('PhoneNumber')"
              :rules="[validators.required, validators.phoneNumberValidator]"
              hide-details="auto"
              class="mb-3"
              validate-on-blur
              :error-messages="errorMessagePhone"
            ></v-text-field>

            <v-text-field
              v-model="password"
              dense
              outlined
              :type="isPasswordVisible ? 'text' : 'password'"
              :label="$t('Password')"
              :placeholder="$t('Password')"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
              :rules="[validators.required]"
              hide-details="auto"
              validate-on-blur
              :error-messages="errorMessagePassword"
              @click:append="isPasswordVisible = !isPasswordVisible"
            ></v-text-field>

            <div class="d-flex align-center justify-space-between flex-wrap">
              <v-checkbox
                v-model="rememberMe"
                :label="$t('RememberMe')"
                hide-details="auto"
                class="me-3 mt-1"
              >
              </v-checkbox>

              <!-- forgot link -->
              <router-link
                :to="{name:'auth-forgot-password'}"
                class="mt-1"
              >
                {{ $t('ForgotPassword') }}?
              </router-link>
            </div>

            <v-btn
              block
              color="primary"
              type="submit"
              class="mt-6"
            >
              {{ $t('Login') }}
            </v-btn>
          </v-form>
        </v-card-text>

        <!-- create new account  -->
        <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
          <span class="me-2">
            {{ $t('RegisterSuggestion') }}
          </span>
          <router-link :to="{name:'auth-register'}">
            {{ $t('CreateAccount') }}
          </router-link>
        </v-card-text>
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="173"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
    >

    <!-- tree -->
    <v-img
      class="auth-tree"
      width="247"
      height="185"
      src="@/assets/images/misc/tree.png"
    ></v-img>

    <!-- tree  -->
    <v-img
      class="auth-tree-3"
      width="377"
      height="289"
      src="@/assets/images/misc/tree-3.png"
    ></v-img>
  </div>
</template>

<script>
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import { required, phoneNumberValidator } from '@core/utils/validation'

// import { useRouter } from '@core/utils'
import CardI18n from '@/components/CardI18n.vue'
import store from '@/store'

export default {
  components: { CardI18n },
  setup() {
    const loginForm = ref(null)

    // const { router } = useRouter()

    const isPasswordVisible = ref(false)

    const phoneNumber = ref('')
    const password = ref('')
    const rememberMe = ref(true)

    const errorMessagePhone = ref('')
    const errorMessagePassword = ref('')

    const handleFormSubmit = () => {
      const isFormValid = loginForm.value.validate()

      if (!isFormValid) return
      store
        .dispatch('auth/login', { phone: phoneNumber.value, password: password.value, rememberMe: rememberMe.value })
        .catch(error => {
          console.log(error.response)

          // Error
          if (error.response?.data?.data?.message) {
            errorMessagePhone.value = ''
            errorMessagePassword.value = ''
            if (error.message.includes('Phone')) {
              errorMessagePhone.value = error.response?.data?.data?.message
            } else {
              errorMessagePassword.value = error.response?.data?.data?.message
            }
          }
        })
    }

    return {
      handleFormSubmit,

      isPasswordVisible,
      phoneNumber,
      password,
      rememberMe,

      errorMessagePhone,
      errorMessagePassword,

      // Icons
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
      validators: {
        required,
        phoneNumberValidator,
      },

      // Template Refs
      loginForm,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';
</style>
